<template>
  <el-dialog
    v-if="payVisible"
    append-to-body
    title="校验交易密码"
    :visible.sync="payVisible"
    :before-close="onCancel"
    width="30%"
  >
    <br />
    <paymentPassBox :passwordVal="passwordVal"></paymentPassBox>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="commitPassword">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import paymentPassBox from '@/views/system/user/paymentPass/paymentPassBox'
export default {
  components: {
    paymentPassBox
  },
  props: {
    payVisible: {
      type: Boolean,
      default: false
    },
    passwordVal: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCancel() {
      this.$emit('update:payVisible', false)
    },
    async commitPassword() {
      await this.$emit('commitPassword')
    }
  }
}
</script>

<style></style>
